<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main" v-if="dateType==1">{{$t('sycm.common.statisticalTime')}} {{ parseInt(Date.now()/1000) | parseTime()}}</div>
			<div class="filter-main" v-else-if="dateType==4">{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<div class="filter-main" v-else>{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}~{{ parseInt(endTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 1}" @click="handleChangeTime(1)">{{$t('sycm.actions.realTime')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 2}" @click="handleChangeTime(2)">7{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">30{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 4}" @click="handleDatePicker(4)">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								@change="changeDatePicker($event,4)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 5}" @click="handleDatePicker(5)">{{$t('sycm.actions.week')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="weekData"
								type="week"
								ref="weekPicker"
								format="WW"
								:placeholder="$t('sycm.placeholder.week')"
								:picker-options="pickerWeekOptions"
								@change="changeDatePicker($event,5)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 6}" @click="handleDatePicker(6)">{{$t('sycm.actions.month')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="monthData"
								type="month"
								ref="monthPicker"
								:placeholder="$t('sycm.placeholder.month')"
								:picker-options="pickerMonthOptions"
								@change="changeDatePicker($event,6)">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.cate.goodsRank')}}</div>
				<div class="barbox" style="min-width: 250px;">
					<el-input v-model="keyword" :placeholder="$t('sycm.placeholder.searchGoods')" size="small" @change="handleSeach">
						<i slot="suffix" class="el-icon-search" style="cursor: pointer" @click="handleSeach"></i>
					</el-input>
				</div>
			</div>
			<div class="section-main">
				<div class="rank-filter">
					<div class="item">
						<div class="item-label">{{$t('sycm.cate.category')}}:</div>
						<div class="item-block">
							<el-radio v-model="cateRadio" :label="1">{{$t('sycm.cate.standard')}}</el-radio>
							<el-popover
								trigger="click"
								width="640"
								v-model="catePopoverVisible"
								popper-class="sycm-popover-cate">
								<div class="item-cate" slot="reference">{{checkCate.cate_name || $t('sycm.cate.standardAll')}}<i class="el-icon-arrow-down"></i></div>
								<div class="popover-cate-trees">
									<el-scrollbar class="trees-menu" v-for="(cate,index) in cateList" :key="index">
										<ul>
											<li class="tree-item" v-if="index == 0" @click="handleChangeCate()">{{$t('sycm.cate.standardAll')}}</li>
											<li class="tree-item" v-for="item in cate" :key="item.cate_id" @mouseenter="mouseCate(item,index)" @click="handleChangeCate(item)"><span>{{item.cate_name}}</span><i class="el-icon-arrow-right"></i></li>
										</ul>
									</el-scrollbar>
								</div>
							</el-popover>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{$t('sycm.cate.contrast')}}:</div>
						<div class="item-block">
							<el-radio v-model="contrastRadio" :label="1">{{$t('sycm.ipoll.meanwhile')}}</el-radio>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{$t('sycm.cate.target')}}:</div>
						<div class="item-block">
							<span>选择 {{goodsColumn.length}}/5</span>
							<a href="javascript:void(0);" class="reset" @click="handleResetColumn">{{$t('sycm.actions.reset')}}</a>
						</div>
					</div>
				</div>
				<div class="rank-column">
					<div class="item">
						<div class="item-label">{{$t('sycm.cate.goodsSales')}}</div>
						<div class="item-block">
							<el-checkbox-group v-model="goodsColumn" @change="handleChageColumn">
								<el-checkbox v-for="(item,index) in $t('sycm.options.goodsSales')" :key="index" :label="item.value">{{item.label}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{$t('sycm.cate.collectAddBuy')}}</div>
						<div class="item-block">
							<el-checkbox-group v-model="goodsColumn" @change="handleChageColumn">
								<el-checkbox v-for="(item,index) in $t('sycm.options.collectAddBuy')" :key="index" :label="item.value">{{item.label}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{$t('sycm.cate.goodsVisit')}}</div>
						<div class="item-block">
							<el-checkbox-group v-model="goodsColumn" @change="handleChageColumn">
								<el-checkbox v-for="(item,index) in $t('sycm.options.goodsVisit')" :key="index" :label="item.value">{{item.label}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>
				<el-table :data="goodsList" @sort-change="handleSortPageChange">
					<el-table-column :label="$t('sycm.tableHead.goods')" min-width="200px">
						<template slot-scope="scope">
							<div class="goods-item">
								<div class="item-pic"><img :src="scope.row.default_image" @error="imageLoadError" alt=""></div>
								<div class="item-main">
									<div class="name">{{scope.row.goods_name}}</div>
									<div class="text">
										<span>ID:{{scope.row.goods_id}}</span>
										<span>{{$t('sycm.ipoll.meanwhile')}}</span>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('payment_amount')" :label="$t('sycm.common.payMoney')" align="right" prop="payment_amount" sortable>
						<template slot="header">
							<span>{{$t('sycm.common.payMoney')}}</span>
							<el-popover
								placement="bottom-start"
								width="477"
								trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.payMoney')+'”'}}</p>
									<p>{{$t('sycm.popover.payMoney1')}}</p>
								</div>
								<div class="popover-main">
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.range')}}</div>
										<div class="item-block">{{$t('sycm.popover.payMoney3')}}</div>
									</div>
									<div class="item">
										<div class="item-label">{{$t('sycm.popover.define')}}</div>
										<div class="item-block">{{$t('sycm.popover.payMoney4')}}</div>
									</div>
								</div>
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>{{ scope.row.payment_amount.values }}</div>
							<div v-if="scope.row.payment_amount.comp_rate !== 0" :class="scope.row.payment_amount.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.payment_amount.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('payment_quantity')" :label="$t('sycm.tableHead.payNumber')" align="right" prop="payment_quantity" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.payment_quantity.values }}</div>
							<div v-if="scope.row.payment_quantity.comp_rate !== 0" :class="scope.row.payment_quantity.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.payment_quantity.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('payment_buyers')" :label="$t('sycm.common.payBuyers')" min-width="90px" prop="payment_buyers" align="right" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.payment_buyers.values }}</div>
							<div v-if="scope.row.payment_buyers.comp_rate !== 0" :class="scope.row.payment_buyers.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.payment_buyers.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('payment_rate')" :label="$t('sycm.common.payConversion')" align="right" min-width="100px" prop="payment_rate" sortable>
						<template slot="header">
							<span>{{$t('sycm.common.payConversion')}}</span>
							<el-popover
								placement="top-start"
								width="477"
								trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+'“'+$t('sycm.common.payConversion')+'”'}}</p>
									<p>{{$t('sycm.popover.payConversion1')}}</p>
								</div>
								<div class="popover-main">
									<el-row>
										<el-col :span="12">
											<div class="popover-formula">
												<div class="formula-title">{{$t('sycm.popover.formula')}}</div>
												<div class="formula-item">
													<div class="item-label">{{$t('sycm.common.payConversion')}} = </div>
													<div class="item-block">
														<div class="text">{{$t('sycm.common.payBuyers')}}</div>
														<div class="fit"></div>
														<div class="text">{{$t('sycm.common.visitor')}}</div>
													</div>
												</div>
											</div>
										</el-col>
										<el-col :span="12">
											<div class="popover-refund">
												<div class="refund-item">
													<div class="item-title red"><i class="el-icon-top"></i>{{$t('sycm.popover.payConversion2')}}</div>
													<div class="item-main">{{$t('sycm.popover.payConversion3')}}</div>
												</div>
												<div class="refund-item">
													<div class="item-title green"><i class="el-icon-bottom"></i>{{$t('sycm.popover.payConversion4')}}</div>
													<div class="item-main">{{$t('sycm.popover.payConversion5')}}</div>
												</div>
											</div>
										</el-col>
									</el-row>
								</div>
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>{{ scope.row.payment_rate.values | priceFilter() }}%</div>
							<div v-if="scope.row.payment_rate.comp_rate !== 0" :class="scope.row.payment_rate.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.payment_rate.comp_rate | priceFilter() }}%
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('goods_into_carts')" :label="$t('sycm.tableHead.goodsAddBuy')" align="right" min-width="100px" prop="goods_into_carts" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.goods_into_carts.values }}</div>
							<div v-if="scope.row.goods_into_carts.comp_rate !== 0" :class="scope.row.goods_into_carts.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.goods_into_carts.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('goods_into_carts_persons')" :label="$t('sycm.cate.goodsAddBuyPer')" align="right" min-width="100px" prop="goods_into_carts_persons" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.goods_into_carts_persons.values }}</div>
							<div v-if="scope.row.goods_into_carts_persons.comp_rate !== 0" :class="scope.row.goods_into_carts_persons.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.goods_into_carts_persons.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('goods_collects')" :label="$t('sycm.cate.goodsCollectPer')" align="right" min-width="100px" prop="goods_collects" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.goods_collects.values }}</div>
							<div v-if="scope.row.goods_collects.comp_rate !== 0" :class="scope.row.goods_collects.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.goods_collects.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('views_into_cart_rate')" :label="$t('sycm.cate.visitPlusRate')" align="right" min-width="120px" prop="views_into_cart_rate" sortable>
						<template slot="header">
							<span>{{$t('sycm.cate.visitPlusRate')}}</span>
							<el-popover
								placement="top-start"
								width="477"
								trigger="hover"
								popper-class="sycm-popover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-head">
									<p>{{$t('sycm.popover.title')+$t('sycm.cate.visitPlusRate')}}</p>
									<p>{{$t('sycm.popover.visitPlusRate1')}}</p>
								</div>
								<div class="popover-main">
									<div class="popover-formula">
										<div class="formula-title">{{$t('sycm.popover.formula')}}</div>
										<div class="formula-item">
											<div class="item-label">{{$t('sycm.cate.visitPlusRate')}} = </div>
											<div class="item-block">
												<div class="text">{{$t('sycm.cate.goodsAddBuyPer')}}</div>
												<div class="fit"></div>
												<div class="text">{{$t('sycm.cate.goodsVisitors')}}</div>
											</div>
										</div>
									</div>
								</div>
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>{{ scope.row.views_into_cart_rate.values | priceFilter() }}%</div>
							<div v-if="scope.row.views_into_cart_rate.comp_rate !== 0" :class="scope.row.views_into_cart_rate.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.views_into_cart_rate.comp_rate | priceFilter() }}%
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('views_collect_rate')" :label="$t('sycm.cate.accessCollectRate')" align="right" min-width="120px" prop="views_collect_rate" sortable>
						<template slot="header">
							<span>{{$t('sycm.cate.accessCollectRate')}}</span>
							<el-popover
								placement="right"
								trigger="hover">
								<i slot="reference" class="el-icon-question"></i>
								<div class="popover-text">{{$t('sycm.popover.accessCollectRate1')}}</div>
							</el-popover>
						</template>
						<template slot-scope="scope">
							<div>{{ scope.row.views_collect_rate.values | priceFilter() }}%</div>
							<div v-if="scope.row.views_collect_rate.comp_rate !== 0" :class="scope.row.views_collect_rate.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.views_collect_rate.comp_rate | priceFilter() }}%
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('goods_visitors')" :label="$t('sycm.tableHead.goodsVisitors')" align="right" min-width="90px" prop="goods_visitors" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.goods_visitors.values }}</div>
							<div v-if="scope.row.goods_visitors.comp_rate !== 0" :class="scope.row.goods_visitors.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.goods_visitors.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
					<el-table-column v-if="goodsColumn.includes('goods_views')" :label="$t('sycm.tableHead.goodsViews')" align="right" min-width="90px" prop="goods_views" sortable>
						<template slot-scope="scope">
							<div>{{ scope.row.goods_views.values }}</div>
							<div v-if="scope.row.goods_views.comp_rate !== 0" :class="scope.row.goods_views.comp_rate > 0 ? 'text-red' : 'text-green'">
								{{ scope.row.goods_views.comp_rate }}
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
				</el-table>
				<div style="text-align: right;margin-top: 10px;">
					<el-pagination :total="pageTotal" :current-page="pageCurr" :page-size="pageSize"
						@current-change="handleCurrentChange" @size-change="handleSizeChange" layout="prev, pager, next"
						:prev-text="$t('sycm.actions.prevPage')" :next-text="$t('sycm.actions.nextPage')"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { categoryList } from '@/api/seller/category'
import { fetchCateGoods } from "@/api/sycm"

const defaultColumn = ['payment_amount', 'payment_quantity', 'payment_rate','goods_into_carts', 'goods_visitors']
export default {
	data() {
		return {
			dateType: 1,
			dayData: '',
			weekData: '',
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			pickerWeekOptions: {
				disabledDate(time) {
					const today = new Date()
					const day = today.getDay()
					today.setDate(today.getDate()-day)
					const end_time = today.getTime()
					today.setDate(today.getDate()-84)
					const start_time = today.getTime()
					return time.getTime() >end_time || time.getTime() < start_time
				},
				firstDayOfWeek: 1
			},
			pickerMonthOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-1)
					const end_time = today.getTime()
					today.setMonth(today.getMonth()-11)
					const start_time = today.getTime()
					return time.getTime() > end_time || time.getTime() < start_time
				}
			},
			startTime: '',
			endTime: '',
			cateRadio: 1,
			contrastRadio: 1,
			catePopoverVisible: false,
			cateKeyword: '',
			cateList: [],
			checkCate: {},
			keyword: '',
			goodsColumn: Object.assign([],defaultColumn),
			goodsDefaultColumn: Object.assign([],defaultColumn),
			goodsList: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 20,
			pageSort: '',
			pageField: '',
		}
	},
	created () {
		this.getCateList()
		this.handleChangeTime()
	},
	methods: {
		imageLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		async getData() {
			const loading = this.$loading()
			try {
				const params = {
					page_index: (this.pageCurr - 1 ) * this.pageSize,
					page_size: this.pageSize,
					time_from: parseInt(this.startTime / 1000),
					time_to: parseInt(this.endTime / 1000),
					date_type: this.dateType,
					cate_id: this.checkCate.cate_id,
					keyword: this.keyword,
					order_type: this.pageSort,
					order_field: this.pageField
				}
				const { data } = await fetchCateGoods(params)
				this.goodsList = data.list
				this.pageTotal = parseInt(data.count)
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		getCateList(pid=0,level=-1) {
			let params = {
				is_platform: 1,
				parent_id: pid
			}
			categoryList(params).then(response => {
				this.cateList.splice(level)
				if(response.data.list.length>0){
					this.cateList.push(response.data.list);
				}
			})
		},
		handleSortPageChange(value) {
			if(value.order == "descending") {
				this.pageSort = 'desc'
			} else {
				this.pageSort = 'asc'
			}
			this.pageField = value.prop
			this.pageCurr = 1
			this.getData()
		},
		handleChangeTime(val) {
			this.dateType = val?val : 1
			let today = new Date()
			switch(this.dateType) {
				case 1:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = Date.now()
					break;
				case 2:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-7).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 3:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
			}
			this.pageCurr = 1
			this.getData()
		},
		handleDatePicker(type) {
			switch(type) {
				case 4:
					this.$refs.dayPicker.focus()
					break;
				case 5:
					this.$refs.weekPicker.focus()
					break;
				case 6:
					this.$refs.monthPicker.focus()
					break;
			}
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			switch(type) {
				case 4:
					this.monthData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
					break;
				case 5:
					this.dayData = ''
					this.monthData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+6).getTime() - 1000
					break;
				case 6:
					this.dayData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth()+1,1).getTime() - 1000
					break;
			}
			this.pageCurr = 1
			this.getData()
		},
		mouseCate(data,level) {
			this.getCateList(data.cate_id,level+1)
		},
		handleChangeCate(data) {
			this.catePopoverVisible = false
			this.checkCate = data || {}
			this.pageCurr = 1
			this.getData()
		},
		handleResetColumn() {
			this.goodsColumn = Object.assign([],defaultColumn)
			this.goodsDefaultColumn = Object.assign([],defaultColumn)
		},
		handleChageColumn(val) {
			if (this.goodsColumn.length > 5) {
				this.goodsColumn.splice(5,1)
				this.$message.error(this.$t('sycm.errorMsg.maxColumn'))
			} else if (this.goodsColumn.length < 1) {
				this.$message.error(this.$t('sycm.errorMsg.minColumn'))
				this.goodsColumn = JSON.parse(JSON.stringify(this.goodsDefaultColumn))
			} else {
				this.goodsDefaultColumn = JSON.parse(JSON.stringify(this.goodsColumn))
			}
		},
		handleCurrentChange(val){
			this.pageCurr = val
			this.getData()
		},
		handleSizeChange(val){
			this.pageSize = val
			this.getData()
		},
		handleSeach() {
			this.pageCurr = 1
			this.getData()
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;
			}

			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;

				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}

			.barbox {
				.item-link {
					color: $--sycm-primary;
					cursor: pointer;
					display: inline-block;

					span {
						margin-right: 5px;
					}

					&:hover {
						color: $--sycm-hover-1;
					}
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.rank-filter {
		padding-top: 6px;
		.item {
			font-size: 12px;
			line-height: 1.5;
			display: flex;
			align-items: center;
			margin-top: 20px;
			.item-label {
				width: 60px;
				flex-shrink: 0;
				color: #333333;
				font-weight: 600;
				margin-right: 8px;
			}
			.item-block {
				flex: 1;
				width: 0;
				::v-deep .el-radio__label {
					font-size: 12px
				}
				.reset {
					margin-left: 10px;
					color: $--sycm-primary;
					:hover {
						color: $--sycm-hover-1;
					}
				}
			}
			.item-cate {
				display: inline-block;
				border: 1px solid #d6d6d6;
				line-height: 28px;
				border-radius: 2px;
				padding: 0 10px;
				cursor: pointer;
				&:hover {
					background-color: #eef1f6;
				}
			}
			.el-popover__reference-wrapper {
				display: inline-block;
			}
		}
	}
	.rank-column {
		margin-top: 6px;
		.item {
			font-size: 12px;
			line-height: 26px;
			display: flex;
			.item-label {
				width: 60px;
				flex-shrink: 0;
				color: #999999;
				margin-right: 8px;
			}
			.item-block {
				::v-deep .el-checkbox__label {
					font-size: 12px
				}
			}
		}
	}
	.goods-item {
		display: flex;
		width: 260px;
		padding-right: 10px;

		.item-pic {
			width: 72px;
			height: 72px;
			margin-right: 10px;
			border: 1px solid #e6e6e6;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.item-main {
			width: 0;
			flex: 1;
			font-size: 12px;
			line-height: 1.5;

			.name {
				color: $--sycm-primary;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.text {
				margin-top: 6px;
				color: #999999;
				span {
					margin-right: 8px;
				}
			}
		}
	}
	
	.text-red {
		color: #fe7c24;
	}
	.text-green {
		color: #00ba26;
	}
}
.el-popover {
	&.sycm-popover-cate {
		.popover-cate-trees {
			height: 310px;
			display: flex;
			border: 1px solid #e6e6e6;
			.trees-menu {
				flex-shrink: 0;
				width: 210px;
				font-size: 12px;
				border-right: 1px solid #e6e6e6;
				.tree-item {
					position: relative;
					padding-left: 16px;
					padding-right: 10px;
					line-height: 34px;
					cursor: pointer;
					display: flex;
					align-items: center;
					span {
						flex: 1;
						width: 0;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
					[class^=el-icon-] {
						flex-shrink: 0;
						margin-left: 10px;
					}
				}
				&:nth-child(3) {
					border-right: none;
				}
			}
		}
	}
}
</style>